<template>
  <div :class="['cy-page-header pt-3 pb-2 px-7', { 'hidden-tabs': hideTabs }]">
    <div class="mb-2 d-flex align-center justify-space-between">
      <v-row
        align="center"
        no-gutters>
        <v-col
          v-if="showBackBtn || $slots.avatar"
          cols="auto">
          <CyButton
            v-if="showBackBtn"
            :to="backRoute"
            theme="primary"
            variant="tertiary"
            icon="arrow_back"
            icon-only
            sm
            class="mr-4"/>
          <div
            v-if="$slots.avatar"
            class="cy-page-header__avatar">
            <v-skeleton-loader
              v-if="loading"
              :loading="loading"
              type="avatar"/>
            <slot
              v-else
              name="avatar"/>
          </div>
        </v-col>
        <v-col>
          <h1 class="cy-page-header__title">
            <v-skeleton-loader
              v-if="loading"
              :loading="loading"
              type="heading"/>
            <slot
              v-else
              name="title"/>
            <span class="cy-page-header__tags">
              <v-skeleton-loader
                v-if="loading"
                :loading="loading"
                type="text"/>
              <slot
                v-else
                name="tags"/>
            </span>
          </h1>
        </v-col>
        <v-col
          cols="auto"
          class="cy-page-header__actions">
          <v-skeleton-loader
            v-if="loading"
            :loading="loading"
            type="actions"/>
          <slot
            v-else
            name="actions"/>
        </v-col>
      </v-row>
    </div>

    <p
      v-show="loading"
      class="cy-page-header__description">
      <v-skeleton-loader
        :loading="loading"
        type="text"/>
    </p>
    <p
      v-show="!loading && $slots.description"
      ref="description"
      :class="['cy-page-header__description', { expanded: descriptionExpanded, 'mb-0': isDescriptionOverflowing }]">
      <slot name="description"/>
    </p>
    <button
      v-if="isDescriptionOverflowing"
      class="expand-button mb-4"
      @click="descriptionExpanded = !descriptionExpanded">
      {{ descriptionExpanded ? $t('seeLess') : $t('seeMore') }}
    </button>

    <div class="cy-page-header__metadata">
      <v-skeleton-loader
        v-if="loading"
        :loading="loading"
        type="list-item-avatar@2"/>
      <slot
        v-else
        name="metadata"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyHeader',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    descriptionExpanded: false,
    isDescriptionOverflowing: false,
  }),
  computed: {
    backRoute () {
      const { params: { backRouteTo, ...params }, meta } = this.$route
      const name = _.find([
        backRouteTo,
        meta?.backRouteTo,
      ], (routeName) => !!routeName && !_.isEqual(routeName, this.$route.name))
      if (!name) return
      return { name, params }
    },
    showBackBtn () {
      return _.every([this.$route?.meta?.showBackBtn, this.backRoute])
    },
    hideTabs () {
      const sectionMatchedDefault = this.$route.matched?.[1]?.props?.default
      const routesWithTabs = [
        'orgSettings',
        'workers',
        'members',
        'teams',
        'logs',
        'projectOverview',
        'projectConfiguration',
        'projectActivity',
        'environmentOverview',
        'environmentActivity',
        'newEnvComponent',
        'envComponentOverview',
        'envComponentInfrastructure',
        'envComponentActivity',
        'envComponentMetrics',
        'envComponentPipeline',
        'envComponentConfiguration',
      ]

      if (['organizations', 'dashboard'].includes(this.$route.name)) return true
      if (this.$route.name === 'envComponentPipeline' && this.$route.params.pipelineCanonical?.startsWith('start-stop-')) return true
      if (routesWithTabs.includes(this.$route.name)) return false

      if (typeof sectionMatchedDefault === 'function') {
        return sectionMatchedDefault(this.$route)?.hideTabs
      }
      return true
    },
  },
  watch: {
    async loading () {
      await this.$nextTick()
      this.isDescriptionOverflowing = this.checkDescriptionOverflowing()
    },
  },
  methods: {
    checkDescriptionOverflowing () {
      const element = this.$refs.description

      if (!element) return false

      if (element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth) {
        return true
      }
      return false
    },
  },
  i18n: {
    messages: {
      en: {
        seeLess: 'See less',
        seeMore: 'See More',
      },
      es: {
        seeLess: 'Ver menos',
        seeMore: 'Ver más',
      },
      fr: {
        seeLess: 'Voir moins',
        seeMore: 'Voir plus',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-skeleton-loader__bone {
  margin-bottom: 0;
}

.cy-page-header {
  background-color: cy-get-color("white");

  .expand-button {
    color: cy-get-color("secondary");
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
  }

  &__avatar {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;

    .cy-stack-avatar {
      min-width: unset !important;
      margin-right: 0 !important;
    }

    ::v-deep .v-skeleton-loader {
      display: inline-block;

      &__avatar {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: $font-size-h3;

    ::v-deep .v-skeleton-loader {
      display: inline-block;
      margin-top: 6px;
      margin-bottom: 6px;

      &__heading {
        width: 184px;
        height: 24px;
      }
    }
  }

  &__tags {
    margin-left: 16px;

    ::v-deep .tag {
      vertical-align: middle;
    }

    ::v-deep .v-skeleton-loader {
      display: inline-block;
      margin-top: 8px;
      margin-bottom: 7px;

      &__text {
        width: 38px;
        height: 20px;
      }
    }
  }

  &__description {
    display: -webkit-box;
    max-width: 85ch;
    margin-bottom: 16px;
    font-size: $font-size-lg;
    -webkit-box-orient: vertical;

    &:not(.expanded) {
      overflow: hidden;
      line-clamp: 2;
    }

    ::v-deep .v-skeleton-loader {
      margin-top: 4px;
      margin-bottom: 3px;

      &__text {
        width: 512px;
        height: 14px;
      }
    }
  }

  &__metadata {
    ::v-deep .v-skeleton-loader {
      display: flex;
      margin-top: 18px;
      margin-bottom: 6px;
      margin-left: 12px;

      &__list-item-avatar {
        width: 76px;
        height: 20px;
        padding: 0;

        &:not(:first-child) {
          margin-left: 20px;
        }
      }

      &__list-item-avatar .v-skeleton-loader__avatar {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      &__list-item-avatar .v-skeleton-loader__text {
        height: 14px;
      }
    }
  }

  &__actions {
    ::v-deep .v-skeleton-loader {
      &__actions {
        height: 36px;
        padding: 0;
      }

      &__button {
        width: 94px;
        height: 36px;
      }
    }
  }
}
</style>
