<template>
  <div
    v-if="isAuthenticated"
    :class="['app-container', {
      'app-container--is-animating': isAnimating, // Prevents horizontal scrollbars while animating
    }]"
    :style="[{
      '--sidebar-width': sidebar.collapsed ? '72px' : '256px',
    }]">
    <CyAlertModal/>

    <CyDevLayer/>

    <transition
      name="slide-fade-left"
      appear>
      <CyMainNav/>
    </transition>

    <transition
      name="slide-fade-right"
      appear
      @before-enter="() => $toggle.isAnimating(true)"
      @after-enter="() => $toggle.isAnimating(false)">
      <v-main
        :class="['main', {
          'main--fit-on-screen': fitOnScreen,
          'main--full-screen': isMobile || isFullScreen,
        }]">
        <div
          v-if="!isMobile && !isFullScreen"
          class="sticky-background"/>

        <CyToolbar/>

        <component
          v-show="!isFullScreen"
          :is="headerComponent"/>

        <div
          :class="[`content-wrapper content-wrapper--${variant}`, {
            'content-wrapper--fit-on-screen': fitOnScreen,
            'content-wrapper--no-padding': noPadding,
          }]">
          <div class="content">
            <slot/>
          </div>
          <back-to-top
            bottom="78px"
            right="4px"
            visibleoffset="800">
            <CyButton
              icon-only
              icon="expand_less"
              theme="primary"/>
          </back-to-top>
        </div>

        <CyFooter/>
      </v-main>
    </transition>

    <portal-target name="side-panel"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyAlertModal from '@/components/CyAlertModal.vue'
import CyDevLayer from '@/components/CyDevLayer.vue'
import CyFooter from '@/components/CyFooter.vue'
import CyHeaderVariantGeneric from '@/components/CyHeaderVariantGeneric.vue'
import CyMainNav from '@/components/CyMainNav.vue'
import CyToolbar from '@/components/CyToolbar.vue'

export default {
  name: 'CyAppLayout',
  components: {
    CyAlertModal,
    CyFooter,
    CyToolbar,
    CyDevLayer,
    CyMainNav,
  },
  props: {
    variant: {
      type: String,
      default: 'full-width',
    },
    fitOnScreen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isAnimating: false,
  }),
  computed: {
    ...mapState({
      isFullScreen: (state) => state.layout.isFullScreen,
    }),
    ...mapState('layout', [
      'sidebar',
      'header',
    ]),
    noPadding () {
      return _.get(this.$route, 'meta.layout.noPadding', false)
    },
    headerComponent () {
      const customHeader = this.$route.meta?.layout?.header

      if (customHeader) return customHeader

      if (this.header) return CyHeaderVariantGeneric

      return null
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
  },
  i18n: {
    messages:
{
  en: {
    welcome: {
      title: 'Welcome to Cycloid!',
    },
  },
  es: {
    welcome: {
      title: '¡Bienvenido a Cycloid!',
    },
  },
  fr: {
    welcome: {
      title: 'Bienvenue chez Cycloid!',
    },
  },
},
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-grow: 1;
  width: 100%;

  &--is-animating {
    overflow: hidden;
  }
}

.main {
  flex-shrink: 1;
  min-width: 0;
  will-change: padding;
  transition-property: padding;

  ::v-deep > .v-main__wrap {
    display: flex;
    flex-direction: column;
  }

  &--fit-on-screen {
    height: 100vh;
  }

  &--full-screen {
    padding-left: 0 !important;
    background-color: cy-get-color("background");
  }
}

.sticky-background {
  position: sticky;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: cy-get-color("secondary");
  background-image: linear-gradient(to bottom, cy-get-color("secondary"), cy-get-color("primary"));

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100vh;
    background-color: cy-get-color("background");
  }

  + * {
    margin-top: -100vh !important;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  min-height: 0;
  padding: 0 32px;

  > .content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-height: 100%;
    padding-bottom: 32px;

  }

  > .spacer {
    flex: 0 1 72px;
    max-width: 72px;
    height: 100%;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &--one-column {
    > .content {
      flex: 0 0 896px;
      width: 896px;
      max-width: 100%;

      @media screen AND (width >= 1736px) {
        flex: 0 0 1240px;
      }

      @media screen AND (width >= 2080px) {
        flex: 0 0 1584px;
      }
    }
  }

  &--full-width {
    > .content {
      flex-grow: 1;
    }
  }

  &--fit-on-screen {
    > .content {
      max-height: 100%;
    }
  }

  &--no-padding {
    padding-inline: 0;

    > .content {
      padding-bottom: 0;
    }
  }
}

.slide-fade-left {
  &-enter-active {
    transition:
      transform 0.5s ease 0.3s,
      opacity 0.2s ease 0.3s;
  }

  &-enter {
    transform: translateX(-20px) !important;
    opacity: 0;
  }
}

.slide-fade-right {
  &-enter-active {
    transition:
      transform 0.5s ease,
      opacity 0.2s ease;
  }

  &-enter {
    transform: translateX(20px) !important;
    opacity: 0;
  }
}

.content:has(.env-component-create) {
  padding-bottom: 0;
}
</style>
