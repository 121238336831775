<template>
  <CyHeader :loading="header.loading">
    <template
      v-if="header.avatar"
      #avatar>
      <CyAvatar
        v-if="header.avatar.type === 'avatar'"
        :item="header.avatar.item"/>
      <CyIconCredential
        v-else-if="header.avatar.type === 'providerIcon'"
        size="30"
        class="mr-4"
        :type="header.avatar.item"/>
      <CyAvatar
        v-else-if="header.avatar.type === 'environmentIcon'"
        :item="header.avatar.item"
        icon-size="24"
        class="mr-1"
        md/>
      <CyStackAvatar
        v-else-if="header.avatar.type === 'stack'"
        size="24px"
        :stack="header.avatar.item"/>
    </template>
    <template #title>
      <div
        v-if="header.icon"
        data-cy="header-icon"
        class="d-flex mr-2"
        v-html="$sanitizeHtml(header.icon)"/>
      {{ header.title }}
      <CyTag
        v-if="header.tag"
        variant="default"
        :icon-before="header.tagIcon || null"
        data-cy="header-tag"
        class="ml-4">
        {{ header.tag }}
      </CyTag>
    </template>
    <template #description>
      <div v-if="header.description">
        <span v-html="header.description.text"/>
        <a
          v-if="header.description.link"
          :href="header.description.link"
          target="_blank"
          rel="noopener noreferrer"
          class="cy-link ml-1"
          v-text="$t('learnMore')"/>
      </div>
    </template>
    <template #actions>
      <portal-target name="header-actions"/>
      <CyButton
        v-if="header.action"
        :icon="header.action.icon"
        variant="secondary"
        theme="primary"
        :to="header.action.to">
        {{ header.action.text }}
      </CyButton>
    </template>
    <template #metadata>
      <CyHeaderMetadata
        v-if="header.owner"
        :label="displayName(header.owner)">
        <CyAvatar
          xs
          :item="header.owner"/>
      </CyHeaderMetadata>
      <CyHeaderMetadata
        v-if="header.type"
        :label="header.type.label"
        data-cy="type-metadata">
        <CyAvatar
          :item="{
            icon: header.type.icon,
            color: header.type.color,
          }"
          sm/>
      </CyHeaderMetadata>
      <CyHeaderMetadata
        v-if="header.organization"
        :label="header.organization.name">
        <CyAvatar
          :item="header.organization"
          sm/>
      </CyHeaderMetadata>
      <CyHeaderMetadata v-if="header.credential">
        <CyIconCredential
          :key="header.credential"
          :type="header.credential"
          size="24"
          disabled/>
        {{ header.credential }}
      </CyHeaderMetadata>
      <CyHeaderMetadata
        v-for="meta in header.metas"
        :key="meta"
        :label="meta"/>
      <CyHeaderMetadata
        v-if="header.createdAt"
        :label="getCreatedLabel(header)"/>
      <CyHeaderMetadata
        v-if="header.updatedAt"
        :label="getUpdatedLabel(header)"/>
    </template>
  </CyHeader>
</template>

<script>
import { mapState } from 'vuex'
import CyHeader from '@/components/CyHeader.vue'
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import { displayName, getCreatedLabel, getUpdatedLabel } from '@/utils/helpers'

export default {
  name: 'CyHeaderVariantGeneric',
  components: {
    CyHeader,
    CyHeaderMetadata,
    CyStackAvatar,
  },
  computed: {
    ...mapState('layout', [
      'header',
    ]),
  },
  methods: {
    displayName,
    getCreatedLabel,
    getUpdatedLabel,
  },
  i18n: {
    messages: {
      en: {
        learnMore: 'Learn more',
      },
      es: {
        learnMore: 'Aprende más',
      },
      fr: {
        learnMore: 'En savoir plus',
      },
    },
  },
}
</script>
